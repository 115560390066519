<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="9" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'vip_insurance-policy_create' }"> 添加 </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="请输入订单编号" />
          </div>
        </b-col>
      </b-row>
    </div>
    <div style="min-width: 80vw">
      <b-table ref="orderListTable" :items="fetchInvoices" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found" class="position-relative">
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <div class="font-medium-1">{{ data.item.create_name }}</div>
          <div class="grid-hero" style="color: #ccc">
            {{ data.value }}
          </div>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(status)="data">
          <b-badge :variant="data.value | filterStatusColor" class="mx-auto">{{ data.value | filterStatusTxt }}</b-badge>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(issuedDate)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(share_number)="data">
          <b-link>
            #<span class="text-nowrap" @click="getMoneyInfo(data.item, data.item.id)"> {{ data.item.share_rate.length }}人 </span>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
            </template>
            <b-dropdown-item @click.prevent="setItem(data)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>详情</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="setItemEdit(data)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>编辑</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="deleteInvoice(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>删除</span>
            </b-dropdown-item> -->
          </b-dropdown>
          <!--        <div class="f-flex">-->
          <!--          &lt;!&ndash;      编辑    &ndash;&gt;-->
          <!--          <b-button size="sm" variant="primary" :to="{ name: 'vip_insurance-policy_edit', params: { id: data.item.id } }" @click="$store.commit('user-order/setOrderDetail', data.item)">-->
          <!--            编辑-->
          <!--            <feather-icon icon="EditIcon" />-->
          <!--          </b-button>-->
          <!--          &lt;!&ndash;      删除    &ndash;&gt;-->
          <!--          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>-->
          <!--            <template v-slot:button-content>-->
          <!--              <b-button size="sm" variant="danger">-->
          <!--                删除-->
          <!--                <feather-icon icon="TrashIcon" />-->
          <!--              </b-button>-->
          <!--            </template>-->
          <!--            <b-dropdown-item>-->
          <!--              <feather-icon icon="TrashIcon" class="mr-50" />-->
          <!--              <span @click="deleteInvoice(data.item.id)">确认删除</span>-->
          <!--            </b-dropdown-item>-->
          <!--          </b-dropdown>-->
          <!--          &lt;!&ndash;          审核&ndash;&gt;-->
          <!--          &lt;!&ndash;          <b-button size="sm" variant="success" @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">&ndash;&gt;-->
          <!--          &lt;!&ndash;            审核&ndash;&gt;-->
          <!--          &lt;!&ndash;            <feather-icon icon="CheckIcon" />&ndash;&gt;-->
          <!--          &lt;!&ndash;          </b-button>&ndash;&gt;-->
          <!--        </div>-->
        </template>
      </b-table>
    </div>

    <b-modal id="audit-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="订单审核">
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group label="订单TP值:">
            <validation-provider #default="{ errors }" name="订单TP" rules="required">
              <b-form-input v-model="checkForm.order_tp" type="email" placeholder="请输入订单TP" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!--          <b-form-group>-->
          <!--            <label>订单状态</label>-->
          <!--            <validation-provider #default="{ errors }" name="审核状态" rules="required">-->
          <!--              <v-select-->
          <!--                v-model="checkForm.status"-->
          <!--                :reduce="item => item.value"-->
          <!--                :placeholder="'请选择状态'"-->
          <!--                :state="errors.length > 0 ? false : null"-->
          <!--                :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"-->
          <!--                :options="[-->
          <!--                  { label: '已提交', value: 1 },-->
          <!--                  { label: '已处理', value: 2 },-->
          <!--                  { label: '已生效', value: 3 },-->
          <!--                ]" />-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </validation-provider>-->
          <!--          </b-form-group>-->
          <!--        改为单选按钮  -->
          <b-form-group label="订单状态">
            <!--            <label>订单状态</label>-->
            <validation-provider #default="{ errors }" name="审核状态" rules="required">
              <b-form-radio-group v-model="checkForm.status" :state="errors.length > 0 ? false : null">
                <!--                <b-form-radio value="1">已提交</b-form-radio>-->
                <b-form-radio value="2">已处理</b-form-radio>
                <b-form-radio value="3">已生效</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal')">取消</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice">确认</b-button>
      </template>
    </b-modal>

    <b-modal id="money-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="分担信息查看" size="lg">
      <b-table :items="fenqianField" responsive :fields="fenqianFieldColumn" primary-key="id" show-empty empty-text="No matching records found" class="position-relative" />
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('money-modal')">关闭</b-button>
      </template>
    </b-modal>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRadio, BFormRadioGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { reactive, getCurrentInstance, nextTick } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import router from '@/router'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return '已提交'
        case 2:
          return '已处理'
        case 3:
          return '已生效'
        default:
          return '未知'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'danger'
      }
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const { auditInvoice: auditInvoiceStore, fetchInvoices, tableColumns, perPage, currentPage, totalInvoices, dataMeta, perPageOptions, searchQuery, orderListTable, deleteInvoice, refetchData } = useInvoicesList(proxy)

    const checkForm = reactive({
      order_tp: '',
      status: '',
      id: '',
    })
    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        if (isOk) {
          auditInvoiceStore(checkForm)
        } else {
          // console.log('error')
        }
      })
    }
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      {
        key: 'user_id',
        label: '会员id',
      },
      {
        key: 'real_name',
        label: '会员名称',
      },
      {
        key: 'level',
        label: '会员等级',
      },
      {
        key: 'share_rate',
        label: '分担比例',
        formatter: (value, row) => `${value}%`,
      },
      {
        key: 'tp',
        label: '分担tp',
        formatter: (value, row) => `${value}`,
      },
      {
        key: 'money',
        label: '分担金额',
      },
      {
        label: '状态',
        key: 'stu_name',
      },
    ]

    const getMoneyInfo = (item, id) => {
      axiosIns.get('/order/getRateInfo', { params: { order_id: id } }).then(response => {
        if (response.code == 0) {
          proxy.$bvModal.show('money-modal')
          nextTick(() => {
            fenqianField.splice(0, fenqianField.length, ...response.data)
          })
        } else {
          proxy.$bvToast.toast(response.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        }
      })
    }
    const setItem = data => {
      store.commit('user-order/setOrderDetail', data.item)

      setTimeout(() => {
        router.push({ name: 'vip_insurance-policy-detail' })
      }, 200)
    }
    const setItemEdit = data => {
      store.commit('user-order/setOrderDetail', data.item)

      setTimeout(() => {
        router.push({ name: 'vip_insurance-policy_edit' })
      }, 200)
    }
    return {
      setItem,
      setItemEdit,
      fenqianField,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,

      refetchData,

      deleteInvoice,
      auditInvoice,
      checkForm,
      required,
      getMoneyInfo,
      fenqianFieldColumn,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
