/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList(proxy) {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'actions',
      label: 'Edit',
      thStyle: {
        width: '100px',
      },
    },
    { key: 'id', label: '#' },
    {
      key: 'number',
      label: 'Policy No.',
    },
    {
      key: 'product.title',
      label: 'Company',
    },
    {
      label: 'Splits',
      key: 'share_number',
    },
    {
      key: 'protect_user',
      label: 'Insured',
      formatter: value => (!value ? '-- 无 --' : `${value}`),
    },

    {
      label: 'Type',
      // key: 'commission_type',
      key: 'count_cate',
      formatter: value => (!value ? '-- 无 --' : `${value}`),
    },
    // {
    //   label: '应收佣金',
    //   key: 'commission_amount',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    // },
    // {
    //   label: '实收佣金',
    //   key: 'paid_commission_amount',
    //   formatter: value => (!value ? '-- 无 --' : `${value}`),
    // },

    {
      key: 'user_income',
      label: 'Commission',
    },

    {
      key: 'submit_at',
      label: 'Submit Date',
    },
    // {
    //   key: 'created_at',
    //   label: 'Submit Date',
    // },
    // {
    //   key: 'apply_time',
    //   label: '生效日期',
    // },
    {
      key: 'desc',
      label: 'Remarks',
      formatter: value => (!value ? '-- 无 --' : `${value}`),
    },
  ]
  const perPage = ref(100)(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const perPageOptions = [10, 25, 50, 100] // pageSize Array
  const searchQuery = ref('') // searchQuery

  const user_id = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value ? orderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('user-order/fetchListData', {
        perPage: perPage.value,
        page: currentPage.value,
        number: searchQuery.value,
        user_id: user_id.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // 删除
  const deleteInvoice = val => {
    store
      .dispatch('user-order/orderDelete', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 审核
  const auditInvoice = val => {
    store
      .dispatch('user-order/orderAudit', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 订单详情

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    orderListTable,
    deleteInvoice,
    auditInvoice,
    refetchData,
  }
}
